import MainLayout from "@/Layouts/MainLayout";
import AppProvider from "@/redux/AppProvider";
import { createInertiaApp } from "@inertiajs/react";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import "moment/dist/locale/ar";
import { createRoot } from "react-dom/client";
import "../css/app.css";
import "./bootstrap";
import { TooltipProvider } from "@/shadcn/ui/tooltip";
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://46e538fd631abd882e2661a9a32db072@o4507704184209408.ingest.de.sentry.io/4508017528406096",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/strivedu\.com\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const appName = import.meta.env.VITE_APP_NAME;

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.tsx`,
            import.meta.glob("./Pages/**/*.tsx"),
        ),
    setup({ el, App, props }: any) {
        // hydrateRoot(
        //     el,
        //     <AppProvider>
        //         <MainLayout>
        //             <App {...props} />
        //         </MainLayout>
        //     </AppProvider>,
        // );
        const root = createRoot(el);
        root.render(
            <AppProvider>
                <MainLayout>
                    <TooltipProvider>
                        <App {...props} />
                    </TooltipProvider>
                </MainLayout>
            </AppProvider>,
        );
    },
    progress: {
        color: "#28844B",
        showSpinner: true,
    },
});
