import { getIcon, getImage } from "@/constants";
import { usePage } from "@inertiajs/react";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";

export default function () {
    const { t } = useTranslation();
    const { setting }: any = usePage().props;
    return (
        <div className="bg-white py-12 sm:py-8 rounded-3xl">
            <div className="mx-auto  px-6 lg:px-8">
                <div className="mx-auto  lg:max-w-none">
                    <div className="flex flex-1 flex-col justify-center items-center gap-y-4">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl capitalize">
                            {t("about_strive")}
                        </h2>
                        <ReactSVG
                            src={getIcon("title_arrow.svg")}
                            className="relative w-20 text-orange-700 ltr:rotate-180"
                        />
                        <p className="text-lg leading-8 text-gray-600">
                            {t("aboutus_message")}
                        </p>
                    </div>
                    <dl className="mt-8 grid grid-cols-1 gap-y-4 overflow-hidden rounded-3xl text-center sm:grid-cols-3">
                        <div className="flex flex-col p-8">
                            <dt className="text-base font-semibold leading-6 text-gray-600">
                                {t("teacher")}
                            </dt>
                            <dd className="order-first text-3xl font-semibold tracking-tight text-theme-700">
                                60+
                            </dd>
                        </div>
                        <div className="flex flex-col p-8">
                            <dt className="text-base font-semibold leading-6 text-gray-600">
                                {t("student")}
                            </dt>
                            <dd className="order-first text-3xl font-semibold tracking-tight text-theme-700">
                                800+
                            </dd>
                        </div>
                        <div className="flex flex-col p-8">
                            <dt className="text-base font-semibold leading-6 text-gray-600">
                                {t("course")}
                            </dt>
                            <dd className="order-first text-3xl font-semibold tracking-tight text-theme-700">
                                100+
                            </dd>
                        </div>
                    </dl>
                </div>
                <img
                    src={getImage("graph.jpeg")}
                    alt={setting.name}
                    loading={"lazy"}
                    className="w-full h-auto lg:h-[500px] rounded-3xl drop-shadow-sm object-cover"
                />
            </div>
        </div>
    );
}
