import { RootState } from '@/redux/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isNull } from 'lodash';
import moment, { Moment } from 'moment';

const initialState: any = {
    mobileMenuOpen: false,
    dateSelected: {
        date: moment().format('L'),
        activeSlide: 0,
    },
    searchModule: {
        id: 1,
        name: 'students',
        type: 'user',
        query: { role: 'student' },
    },
    calendarView: 'week',
    deleteModal: { showDeleteModal: false, element: null },
    infoModal: { showInfoModal: false, element: null },
    sortModal: { showSortModal: false, element: null },
    popupModal: { viewed: false, nextView: null },
    videoModal: { visible: false, url: null },
};

export const appSettingSlice = createSlice({
    name: 'appSetting',
    initialState,
    reducers: {
        toggleMobileMenu: (state, action: PayloadAction<void>) => ({
            ...state,
            mobileMenuOpen: !state.mobileMenuOpen,
        }),
        toggleshowDeleteModal: (
            state,
            action: PayloadAction<{ name: string; id: number | string } | null>,
        ) => ({
            ...state,
            deleteModal: {
                showDeleteModal: !isNull(action.payload),
                element: action.payload,
            },
        }),
        toggleShowInfoModal: (
            state,
            action: PayloadAction<{ name: string; id: number | string } | null>,
        ) => ({
            ...state,
            infoModal: {
                showInfoModal: !isNull(action.payload),
                element: action.payload,
            },
        }),
        toggleSortModal: (state, action: PayloadAction<string | null>) => ({
            ...state,
            sortModal: {
                showSortModal: !isNull(action.payload),
                element: action.payload,
            },
        }),
        setCurrentDate: (state, action: PayloadAction<Moment>) => ({
            ...state,
            dateSelected: {
                ...state.dateSelected,
                date: moment(action.payload).locale('en').format('L'),
            },
        }),
        setCurrentSlide: (state, action: PayloadAction<number>) => ({
            ...state,
            dateSelected: { ...state.dateSelected, activeSlide: action.payload },
        }),
        resetDateSelected: (state, action: PayloadAction<Moment>) => ({
            ...state,
            dateSelected: {
                ...state.dateSelected,
                date: moment(action.payload).locale('en').format('L'),
            },
        }),
        setSearchModule: (state, action: PayloadAction<object>) => ({
            ...state,
            searchModule: action.payload,
        }),
        setCalenderView: (state, action: PayloadAction<string>) => ({
            ...state,
            calendarView: action.payload,
        }),
        resetSettings: (state, action: PayloadAction<void>) => ({
            ...initialState,
        }),
        toggleViewedPopupModal: (state, action: PayloadAction<boolean>) => ({
            ...state,
            popupModal: {
                ...state.popupModal,
                viewed: action.payload,
            },
        }),
        closePopupModal: (state, action: PayloadAction<void>) => ({
            ...state,
            popupModal: {
                ...state.popupModal,
                nextView: moment().locale('en').add(3, 'minute').format('lll'),
            },
        }),
        togggleVideoModal: (state, action: PayloadAction<string | void>) => ({
            ...state,
            videoModal: {
                visible: !state.videoModal.visible,
                url: action.payload ?? '',
            },
        }),
        resetEntireApp: (state, action: PayloadAction<void>) => ({
            ...initialState,
        }),
    },
});

export const {
    toggleMobileMenu,
    toggleshowDeleteModal,
    toggleShowInfoModal,
    toggleSortModal,
    setCurrentDate,
    setCurrentSlide,
    resetDateSelected,
    setSearchModule,
    setCalenderView,
    toggleViewedPopupModal,
    closePopupModal,
    togggleVideoModal,
    resetEntireApp,
} = appSettingSlice.actions;
export const dateSelected = (state: RootState) => state.appSetting.dateSelected;
