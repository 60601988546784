import Modal from "@/Components/Modal";
import { getImage } from "@/constants";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { togggleVideoModal } from "@/redux/slices/appSettingSlice";
import { VideoPlayer, VideoPlayerProps } from "@graphland/react-video-player";
import { XMarkIcon } from "@heroicons/react/24/outline";

export default function () {
    const {
        locale: { dir },
        appSetting: { videoModal },
    } = useAppSelector((state) => state);
    const dispatch = useAppDispatch();
    const { innerWidth: width, innerHeight: height } = window;

    const closeModal = () => {
        dispatch(togggleVideoModal());
    };

    const videoProps: VideoPlayerProps = {
        theme: "sea", // 'city', 'fantasy', 'forest', 'sea'
        height: innerWidth > 500 ? 672 : 250,
        width: innerWidth > 500 ? 896 : 400,
        autoPlay: false,
        loop: false,
        poster: getImage("logo.png"),
        sources: [{ src: videoModal?.url, type: "video/mp4" }],
        controlBar: {
            skipButtons: {
                forward: 5,
                backward: 5,
            },
        },
        playbackRates: [0.5, 1, 1.5, 2],
        disablePictureInPicture: true,
        // onReady: () => {
        //     console.log("Video player is ready!");
        // },
    };

    return (
        <Modal show={videoModal?.visible} onClose={() => null} maxWidth="4xl">
            <div
                dir={dir}
                className="flex flex-col justify-center items-center rounded-2xl"
            >
                <button
                    onClick={() => closeModal()}
                    className="flex flex-row justify-end items-center h-10 relative top-0 z-10 w-full border-none"
                >
                    <XMarkIcon className=" mx-3 w-6 h-6 text-theme-600 border-none" />
                </button>
                <VideoPlayer {...videoProps} />
            </div>
        </Modal>
    );
}
